<template>
  <app-overlay style="min-height: fit-content" loading-variable="modalOverlay">
    <div :class="{ 'min-70': modalOverlay }">
      <b-alert variant="warning" :show="showAlert" fade>
        <div class="alert-body">
          <feather-icon class="mr-50" icon="InfoIcon" />
          <span>Henüz fotoğraf eklenmemiştir. </span>
        </div>
      </b-alert>
      <swiper @ready="onSwiper" ref="mySwiper" @slide-change="onSwiperSlideChange" :options="swiperOptions" :key="swiperKey">
        <swiper-slide v-for="(data, index) in items" :key="index">
          <div style="display: flex; justify-content: center; align-items: center">
            <img v-bind:src="data.image" class="gallery-image" fluid />
          </div>
        </swiper-slide>

        <div slot="pagination" class="swiper-pagination" />
        <div slot="button-next" class="swiper-button-next" />
        <div slot="button-prev" class="swiper-button-prev" />
      </swiper>
    </div>
  </app-overlay>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      showAlert: false,
      swiperKey: 0,
      swiperInstance: null,
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        lazy: true,
      },
    };
  },
  computed: {
    modalOverlay() {
      return this.$store.getters.modalOverlay;
    },
    items() {
      return this.$store.getters.appSidebarItem?.files;
    },
    item() {
      return this.$store.getters.appSidebarItem;
    },
    route() {
      return this.$store.getters.appModalItem?.route;
    },
  },
  methods: {
    onSwiper(swiper) {
      swiper.slideTo(this?.swiperInstance?.activeIndex ?? 0, 0, false);
      this.swiperInstance = swiper;
    },
    onSwiperSlideChange() {
      !this.items[this.swiperInstance.activeIndex]?.image && this.setImage(this.items[this.swiperInstance.activeIndex]?._id);
    },
    setImage(item) {
      this.$axios
        .get(`/${this.route}/get-image`, { params: { id: this.item._id, fileId: item }, loading: "modal", responseType: "arraybuffer" })
        .then((result) => {
          const base64Image = btoa(new Uint8Array(result.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
          this.items[this.swiperInstance.activeIndex].image = `data:image/png;base64,${base64Image}`;
          this.swiperKey += 1;
        })
        .catch((err) => {
          console.log(err);
          this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
        });
    },
  },
  watch: {
    items() {
      this.showAlert = Boolean(!this.items.length);
      this.items.length && !this.items[0]?.image && this.setImage(this.items[0]?._id);
    },
  },
  mounted() {
    this.showAlert = Boolean(!this.items.length);
    this.items.length && !this.items[0]?.image
      ? this.setImage(this.items[0]?._id)
      : setTimeout(() => {
          this.swiperKey += 1;
        }, 300);
  },
};
</script>

<style>
.gallery-image {
  text-align: center;
  max-height: 525px;
}
.min-70 {
  min-height: 70vh;
}
</style>
