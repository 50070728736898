<template>
  <app-overlay loading-variable="modalOverlay">
    <show-image-modal ref="showImageModal" />
    <searchable-table class="border mb-50" :items="items" :fields="fields">
      <template #modalbutton>
        <app-button v-if="!training.isStarted" @click="showAddEmployeeForm" text="Katılımcı Ekle" icon="PlusIcon" size="md" />
      </template>
      <template #actions="{ item }">
        <app-dropdown v-if="training.isStarted && windowWidth < 480">
          <b-dropdown-item @click="createDigitalCertificate(item)">
            <feather-icon :icon="item.isDigitalWsCertificateUploaded ? 'FileTextIcon' : 'PlusIcon'" />
            <span class="ml-50">Dijital Sertifika</span>
          </b-dropdown-item>
          <b-dropdown-item @click="checkIsOriginalCertificateUploaded(item)">
            <feather-icon :icon="item.isOriginalWsCertificateUploaded ? 'FileTextIcon' : 'PlusIcon'" />
            <span class="ml-50">Sertifika Aslı</span>
          </b-dropdown-item>
        </app-dropdown>
        <div v-else class="d-flex gap-3">
          <app-button
            v-if="training.isStarted"
            @click="createDigitalCertificate(item)"
            text="Dijital Sertifika"
            :icon="item.isDigitalWsCertificateUploaded ? 'FileTextIcon' : 'PlusIcon'"
          />
          <app-button
            v-if="training.isStarted"
            @click="checkIsOriginalCertificateUploaded(item)"
            text="Sertifika Aslı"
            :icon="item.isOriginalWsCertificateUploaded ? 'FileTextIcon' : 'PlusIcon'"
          />
          <app-button v-if="!training.isStarted" @click="deleteEmployee(item)" text="Kaldır" icon="TrashIcon" />
        </div>
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import UploadOriginalCertificateForm from "./UploadOriginalCertificateForm.vue";
import ShowImageModal from "./OriginalParticipationForm/ShowImageModal.vue";
import AddEmployeeForm from "./AddEmployeeForm.vue";
export default {
  components: { ShowImageModal },
  data() {
    return {
      fields: [
        { key: "employee.fullname", label: "Ad Soyad" },
        { key: "employee.duty", label: "Unvan" },
        { key: "actions", label: "İşlemler" },
      ],
    };
  },
  computed: {
    items() {
      return this.training?.employees ?? [];
    },
    training() {
      return this.$store.getters.appModalItem;
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
  methods: {
    createDigitalCertificate(item) {
      this.$axios
        .get("/trainings/employee-certificate", {
          params: {
            trainingId: this.training?.id,
            employeeId: item?.employee?._id,
          },
          loading: "modal",
          responseType: "blob",
        })
        .then((result) => {
          if (!item.isDigitalWsCertificateUploaded) {
            const index = this.training?.employees?.findIndex((x) => x._id === item._id);
            this.training.employees[index].isDigitalWsCertificateUploaded = true;
            this.$store.commit("setAppModalOptions", { item: this.training });
          }
          const url = window.URL.createObjectURL(new Blob([result.data], { type: "application/pdf" }));
          const newWindow = window.open(url, "_blank");
          if (!newWindow) {
            alert("Pop-up engelleyiciniz aktif durumdadır. Dökümanı görüntüleyebilmek için lütfen engelleyiciyi kapatınız.");
          }
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message ?? null });
        });
    },
    checkIsOriginalCertificateUploaded(item) {
      if (item?.isOriginalWsCertificateUploaded) {
        if (item?.image) {
          this.$refs.showImageModal.selectedImage = item.image;
          this.$refs.showImageModal.show();
        } else {
          this.$axios
            .get("/trainings/employee-certificate/original", {
              params: { trainingId: this?.training?._id, employeeId: item?.employee?._id },
              loading: "modal",
              responseType: "arraybuffer",
            })
            .then((result) => {
              const base64Image = btoa(new Uint8Array(result.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
              this.$refs.showImageModal.selectedImage = `data:image/png;base64,${base64Image}`;
              this.$refs.showImageModal.show();
              item.image = `data:image/png;base64,${base64Image}`;
            })
            .catch((err) => {
              this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
            });
        }
      } else
        this.$showAppModalSidebar("Sertifika Aslı Yükle", UploadOriginalCertificateForm, {
          trainingId: this.training?._id,
          employeeId: item?.employee?._id,
        });
    },
    showAddEmployeeForm() {
      this.$showAppModalSidebar("Katılımcı Ekle", AddEmployeeForm);
    },
    deleteEmployee(item) {
      this.$confirm({ message: "Çalışanı eğitimden çıkarmak istediğinize emin misiniz?" }, () => {
        this.$axios
          .delete(`/trainings/${this.training?._id}/delete-employee`, { params: { employeeId: item?.employee?._id }, loading: "modal" })
          .then((result) => {
            this.$store.commit("setAppModalOptions", { item: result.data });
            this.$emitter.$emit("Notification", { variant: "success", title: "Başarılı", message: "Çalışan eğitimden çıkarıldı." });
          })
          .catch((err) => {
            console.log(err);
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
          });
      });
    },
  },
};
</script>

<style></style>
