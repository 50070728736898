<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="certificateUpload">
      <small>
        - Sadece JPG ve PNG dosya formatı yüklenebilir. <br />
        - Bir resim en fazla 2 MB boyutunda olabilir.<br />
      </small>
      <hr />
      <app-file-input v-model="file" :accept="$acceptMimes(['jpg', 'jpeg', 'png'])" requires />
      <hr />
      <app-button @click="uploadFile" icon="UploadIcon" text="Dosya Yükle" block />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: {
    ValidationObserver,
  },
  data() {
    return {
      file: null,
    };
  },
  computed: {
    item() {
      return this.$store.getters.appModalSidebarItem;
    },
    training() {
      return this.$store.getters.appModalItem;
    },
  },
  methods: {
    setData() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("trainingId", this.item?.trainingId);
      formData.append("employeeId", this.item?.employeeId);
      return formData;
    },
    uploadFile() {
      this.$validate(this.$refs.certificateUpload, () => {
        this.$axios
          .post(`/trainings/upload-original-certificate`, this.setData(), { loading: "sidebar" })
          .then((result) => {
            const { data } = result;
            const index = this.training?.employees?.findIndex((x) => x._id === data._id);
            this.training.employees[index].originalWsCertificate = data.originalWsCertificate;
            this.training.employees[index].isOriginalWsCertificateUploaded = data.isOriginalWsCertificateUploaded;
            this.$store.commit("setAppModalOptions", { item: this.training });
            this.$store.commit("cleanAppModalSidebar");
            this.$emitter.$emit("Notification", { variant: "success", title: "İşlem Başarılı", message: "Dosya sisteme başarıyla yüklendi." });
          })
          .catch((err) => {
            console.log(err);
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
          });
      });
    },
  },
};
</script>

<style></style>
