<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="photoUpload">
      <small>
        - Sadece JPG ve PNG dosya formatı yüklenebilir. <br />
        - Bir resim en fazla 2 MB boyutunda olabilir.<br />
        - En fazla 10 adet resim yükleyebilirsiniz.
      </small>
      <hr />
      <app-file-input v-model="files" :accept="$acceptMimes(['jpg', 'jpeg', 'png'])" multiple requires />
      <small class="text-danger">{{ error }}</small>
      <hr />
      <app-button @click="uploadFiles" class="flex-1-md" icon="UploadIcon" text="Dosya Yükle" block />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: {
    ValidationObserver,
  },
  data() {
    return {
      files: [],
    };
  },
  computed: {
    error() {
      return this?.files?.length > 10 ? "En fazla 10 dosya yükleyebilirsiniz." : null;
    },
    item() {
      return this.$store.getters.appModalItem;
    },
  },
  methods: {
    setData() {
      const formData = new FormData();
      this.files.forEach((file) => formData.append("files", file));
      return formData;
    },
    uploadFiles() {
      this.$validate(this.$refs.photoUpload, () => {
        this.$axios
          .post(`/trainings/${this.item?._id}/upload-photo`, this.setData(), { loading: "sidebar" })
          .then((result) => {
            this.$store.commit("setAppModalOptions", { item: { ...this.item, photos: result?.data ?? [] } });
            this.$store.commit("cleanAppModalSidebar");
            this.$emitter.$emit("Notification", { variant: "success", title: "İşlem Başarılı", message: "Dosyalar sisteme başarıyla yüklendi." });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
          });
      });
    },
  },
};
</script>

<style></style>
