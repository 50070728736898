<template>
  <b-card body-class="p-1" class="border mb-50">
    <b-custom-tabs id="trainingPhotoTabs" :tabs="tabs" lazy></b-custom-tabs>
  </b-card>
</template>

<script>
import TrainingPhotoGallery from "./TrainingPhotoGallery.vue";
import TrainingPhotoList from "./TrainingPhotoList.vue";
export default {
  data() {
    return {
      tabs: [
        { title: "Liste", component: TrainingPhotoList },
        { title: "Galeri", component: TrainingPhotoGallery },
      ],
    };
  },
};
</script>

<style></style>
