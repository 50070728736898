<template>
  <app-overlay loading-variable="modalOverlay">
    <b-row>
      <b-col md="6">
        <b-card body-class="p-50" class="border mb-50">
          <app-detail-table :data="item" :fields="fields">
            <template #instructor="data">
              <app-user-info v-if="data.instructor" :title="data.instructor.auth.fullname" :alt_title="data.instructor.auth.role.value" icon="" variant="secondary" />
              <span v-else> - </span>
            </template>
          </app-detail-table>
          <hr class="mt-0" />
          <div class="d-flex gap-5 flex-wrap">
            <app-button
              @click="isStartedCheckCofirm"
              size="md"
              class="flex-1-md"
              :icon="item.digitalParticipationForm ? 'FileTextIcon' : 'PlusIcon'"
              :text="'Dijital Katılım Formu' + (!item.digitalParticipationForm ? ' Oluştur' : '')"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: "company.name", label: "Firma" },
        { key: "branch.name", label: "Sicil" },
        { key: "trainingDate", label: "Eğitim Tarihi", formatDate: true },
        { key: "subject", label: "Eğitim Konusu" },
        { key: "hours", label: "Eğitim Süresi", addition: "Saat" },
        { key: "instructor", label: "Eğitimi Veren" },
      ],
    };
  },
  computed: {
    item() {
      return this.$store.getters.appModalItem;
    },
  },
  methods: {
    createDigitalParticipationForm() {
      this.$axios
        .get(`/trainings/${this.item?._id}/digital-participation-form`, { loading: "modal", responseType: "blob" })
        .then((result) => {
          const blob = new Blob([result.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const newWindow = window.open(url, "_blank");
          if (!newWindow) {
            alert("Pop-up engelleyiciniz aktif durumdadır. Dökümanı görüntüleyebilmek için lütfen engelleyiciyi kapatınız.");
          }
          this.$store.commit("setAppModalOptions", {
            item: { ...this.item, digitalParticipationForm: true, isStarted: true },
          });
          this.$store.commit("setTrainingAsStarted", this.item._id);
        })
        .catch((err) => {
          err.then((error) => {
            error = JSON.parse(error);
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: error?.message ?? null });
          });
        });
    },
    isStartedCheckCofirm() {
      !this.item.isStarted
        ? this.$confirm({ message: "Dijital Katılım Formu oluşturulduğunda eğitim başlatılmış sayılacaktır. Onaylıyor musunuz?" }, () => {
            this.createDigitalParticipationForm();
          })
        : this.createDigitalParticipationForm();
    },
  },
};
</script>

<style></style>
