<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getData" class="border mb-50" :items="items" :total-rows="totalCount" :fields="fields" searchable>
      <template #modalbutton>
        <app-button v-if="!isCompanyArchived" class="flex-1-md" text="Yeni Ekle" icon="PlusIcon" size="md" @click="$showAppSidebar('Olay Ekle', formVue)" />
      </template>
      <template #actions="{ item }">
        <div class="d-flex gap-3">
          <app-icon-button icon="EyeIcon" variant="gradient-danger" @click="showDetailSidebar(item)" />
          <app-icon-button v-if="!isCompanyArchived" icon="TrashIcon" variant="gradient-danger" @click="deleteItem(item)" />
        </div>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import formVue from "./form.vue";
import showDetails from "./showDetails.vue";

export default {
  data() {
    return {
      filters: {},
      formVue,
    };
  },
  computed: {
    items() {
      return this.$store.getters?.incidents?.data || [];
    },
    totalCount() {
      return this.$store.getters?.incidents?.total || 0;
    },
    selectedCompany() {
      return this.$store.getters?.selectedCompany;
    },
    selectedBranch() {
      return this.$store.getters?.selectedBranch;
    },
    isCompanyArchived() {
      return this.$store.getters.selectedCompany?.isArchived;
    },
    fields() {
      return [
        ...(this.selectedBranch ? [] : [{ key: "branch.name", label: "Sicil" }]),
        { key: "title", label: "Başlık" },
        { key: "createdAt", label: "Oluşturulma Tarihi", formatDateTime: true },
        { key: "actions", label: "İşlemler" },
      ];
    },
    visit() {
      return this.$store.getters.visit;
    },
  },
  watch: {
    selectedBranch() {
      this.getData();
    },
  },
  destroyed() {
    this.$store.commit("setIncidents", []);
  },
  methods: {
    showDetailSidebar(item) {
      this.$axios
        .get("/actions/incident", { params: { _id: item._id }, loading: "table" })
        .then((res) => {
          this.$showAppSidebar(res.data.title, showDetails, res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      const query = this.visit
        ? {
            visit: this.visit._id,
          }
        : {
            company: this.selectedCompany?._id,
            branch: this.selectedBranch ? this.selectedBranch : undefined,
          };
      this.$axios
        .get("/actions/incidents/paginated", {
          params: {
            ...this.filters,
            ...query,
            populates: !this.selectedBranch ? JSON.stringify(["branch", "addedBy"]) : undefined,
          },
          loading: "table",
        })
        .then((result) => {
          this.$store.commit("setIncidents", result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteItem(item) {
      this.$confirm({ message: "Kayıt ile beraber tüm resimler silinecektir. Silmek istediğinize emin misiniz?" }, () => {
        this.$axios
          .delete(`/actions/incidents/${item._id}`, { loading: "table" })
          .then(() => {
            this.$store.commit("deleteIncident", item._id);
            this.$emitter.$emit("Notification", { variant: "success", title: "İşlem başarılı" });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu" });
            console.log(err);
          });
      });
    },
  },
};
</script>

<style></style>
