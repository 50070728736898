<template>
  <app-overlay loading-variable="modalOverlay">
    <show-image-modal ref="showFormImage" />
    <searchable-table :items="items" :fields="fields">
      <template #modalbutton>
        <app-button @click="$showAppModalSidebar('Dosya Yükle', OriginalParticipationFormUpload)" class="flex-1-md" icon="UploadIcon" text="Dosya Yükle" size="md" />
      </template>
      <template #actions="{ item }">
        <div class="d-flex gap-5">
          <app-icon-button @click="showImage(item)" icon="EyeIcon" variant="gradient-danger" />
          <app-icon-button @click="deleteForm(item)" icon="TrashIcon" variant="gradient-danger" />
        </div>
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import OriginalParticipationFormUpload from "./OriginalParticipationFormUpload.vue";
import ShowImageModal from "./ShowImageModal.vue";
export default {
  components: { ShowImageModal },
  data() {
    return {
      fields: [
        { key: "createdAt", label: "Oluşturulma Tarihi", formatDateTime: true },
        { key: "actions", label: "İşlemler" },
      ],
      OriginalParticipationFormUpload,
    };
  },
  computed: {
    items() {
      return this.$store.getters.appModalItem?.originalParticipationForms ?? [];
    },
    item() {
      return this.$store.getters.appModalItem;
    },
  },
  methods: {
    showImage(item) {
      if (item?.image) {
        this.$refs.showFormImage.selectedImage = item.image;
        this.$refs.showFormImage.show();
      } else {
        this.$axios
          .get(`/trainings/${this.item._id}/get-original-form`, { params: { fileId: item?._id }, loading: "modal", responseType: "arraybuffer" })
          .then((result) => {
            const base64Image = btoa(new Uint8Array(result.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
            item.image = `data:image/png;base64,${base64Image}`;
            this.$refs.showFormImage.selectedImage = `data:image/png;base64,${base64Image}`;
            this.$refs.showFormImage.show();
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
          });
      }
    },
    deleteForm(item) {
      this.$confirm({ message: "Silmek istediğinize emin misiniz?" }, () => {
        this.$axios
          .delete(`/trainings/${item._id}/delete-original-form`, { loading: "modal" })
          .then((result) => {
            this.$emitter.$emit("Notification", { variant: "success", title: "Başarılı", message: "Dosya silindi." });
            this.$store.commit("setAppModalOptions", {
              item: { ...this.item, originalParticipationForms: this.item?.originalParticipationForms.filter((form) => form._id !== item._id) },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style></style>
