<template>
  <app-overlay>
    <db-searchable-table
      v-model="filters"
      @changed="getData"
      @row-clicked="showDetails"
      class="border mb-50"
      :items="trainings"
      :total-rows="totalCount"
      :fields="fields"
      hover
      searchable
    >
      <template #modalbutton>
        <app-button
          v-if="!isCompanyArchived"
          @click="$showAppSidebar(visit ? 'Eğitim Yap' : 'Eğitim Planla', PlanTrainingForm)"
          class="flex-1-md"
          size="md"
          :text="visit ? 'Eğitim Yap' : 'Eğitim Planla'"
          icon="PlusIcon"
        />
      </template>
      <template #instructor="data">
        <app-user-info v-if="data.item.instructor" :title="data.item.instructor.auth.fullname" :alt_title="data.item.instructor.auth.role.value" icon="" variant="secondary" />
        <span v-else> - </span>
      </template>
      <template #actions="{ item }"> <app-icon-button @click="deleteTraining(item)" v-if="!item.isStarted" variant="gradient-danger" icon="TrashIcon" /> </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import PlanTrainingForm from "./PlanTrainingForm.vue";
import TrainingDetailModal from "./TrainingDetailModal/TrainingDetailModal.vue";
export default {
  data() {
    return {
      filters: {},
      PlanTrainingForm,
    };
  },
  computed: {
    trainings() {
      return this.$store.getters.trainings?.data || [];
    },
    totalCount() {
      return this.$store.getters.trainings?.total || 0;
    },
    fields() {
      return [
        ...(!this.selectedBranch ? [{ key: "branch.name", label: "Sicil" }] : []),
        { key: "subject", label: "Konu" },
        { key: "trainingDate", label: "Planlama Tarihi", formatDate: true },
        { key: "dateOfStartTraining", label: "Eğitim Tarihi", formatDate: true },
        { key: "instructor", label: "Eğitim Veren" },
        { key: "actions", label: "İşlemler" },
      ];
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    visit() {
      return this.$store.getters.visit;
    },
    isCompanyArchived() {
      return this.$store.getters.selectedCompany?.isArchived;
    },
  },
  methods: {
    getData() {
      const query = this.visit
        ? {
            visit: this?.visit?._id,
          }
        : {
            company: this.selectedCompany,
            branch: this.selectedBranch ?? undefined,
          };
      this.$store.dispatch("getTrainings", {
        actionType: "setTrainings",
        query: {
          ...this.filters,
          ...query,
          searchFields: this.filters.search ? JSON.stringify(["subject"]) : null,
        },
      });
    },
    showDetails(item) {
      this.$axios
        .get("/trainings/get-one", { params: { _id: item?._id }, loading: "table" })
        .then((result) => {
          this.$showAppModal({ title: "Eğitim Detayları", component: TrainingDetailModal, centered: false, size: "custom", item: result?.data });
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
        });
    },
    deleteTraining(item) {
      this.$confirm({ message: "Eğitimi silmek istediğinize emin misiniz?" }, () => {
        this.$axios
          .delete(`/trainings/${item?._id}`, { loading: "table" })
          .then((result) => {
            this.$store.commit("deleteTraining", item?._id);
            this.$emitter.$emit("Notification", { variant: "success", title: "İşlem Başarılı", message: result?.data?.message ?? null });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
          });
      });
    },
  },
  watch: {
    selectedBranch() {
      this.getData();
    },
  },
  destroyed() {
    this.$store.commit("setTrainings", []);
  },
};
</script>
