<template>
  <b-custom-tabs id="trainingDetailTabs" :tabs="tabs" />
</template>

<script>
import OriginalParticipationFormList from "./OriginalParticipationForm/OriginalParticipationFormList.vue";
import TrainingDetailTable from "./TrainingDetailTable.vue";
import TrainingEmployeeList from "./TrainingEmployeeList.vue";
import TrainingPhotoTabs from "./TrainingPhotos/TrainingPhotoTabs.vue";
export default {
  computed: {
    training() {
      return this.$store.getters.appModalItem;
    },
    tabs() {
      return [
        { title: "Eğitim Bilgileri", component: TrainingDetailTable },
        { title: "Katılanlar", component: TrainingEmployeeList },
        ...(this.training.isStarted
          ? [
              { title: "Katılım Formu Aslı", component: OriginalParticipationFormList },
              { title: "Fotoğraflar", component: TrainingPhotoTabs },
            ]
          : []),
      ];
    },
  },
};
</script>

<style></style>
