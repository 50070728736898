<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="observationsForm">
      <branch-select v-if="!visit" v-model="data.branch" :company="selectedCompany" :reduce="(option) => option._id" required />
      <hr v-if="!visit" />
      <b-form-radio-group v-model="statusSelected" :options="radioOptions" value-field="value" text-field="text" />
      <hr />
      <app-input v-model="data.title" name="Başlık" placeholder="Başlık..." label="Başlık:" rules="required" />
      <app-textarea-input v-model="data.description" name="Açıklama" label="Açıklama:" placeholder="Açıklama..." rules="required" />
      <app-input v-if="!statusSelected" v-model="data.dof" name="DÖF" placeholder="DÖF..." label="DÖF:" rules="required" />
      <hr />
      <small>
        - Sadece JPG ve PNG dosya formatı yüklenebilir. <br />
        - Bir resim en fazla 2 MB boyutunda olabilir.<br />
        - En fazla 10 adet resim yükleyebilirsiniz.
      </small>
      <hr />
      <app-file-input v-model="data.files" accept="image/jpeg,image/png" multiple />
      <small class="text-danger">{{ error }}</small>
      <hr />
      <app-button text="Oluştur" block @click="create" />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver },
  data() {
    return {
      data: {
        files: [],
      },
      radioOptions: [
        { text: "Olumlu", value: true },
        { text: "Olumsuz", value: false },
      ],
      statusSelected: true,
    };
  },
  computed: {
    error() {
      return this?.data?.files?.length > 10 ? "En fazla 10 dosya yükleyebilirsiniz." : null;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    visit() {
      return this.$store.getters.visit;
    },
  },
  mounted() {
    this.data.branch = this.selectedBranch;
  },
  methods: {
    setData() {
      const formData = new FormData();
      formData.append("title", this.data.title);
      this.data.files.forEach((file) => formData.append("files", file));
      formData.append("description", this.data.description);
      this.data?.dof && formData.append("dof", this.data.dof);
      formData.append("company", this?.visit?.company?._id ?? this.selectedCompany);
      formData.append("branch", this?.visit?.branch?._id ?? this.data.branch);
      this.visit && formData.append("visit", this.visit._id);
      return formData;
    },
    create() {
      this.$validate(this.$refs.observationsForm, () => {
        this.$axios
          .post("/actions/observations", this.setData(), { loading: "sidebar" })
          .then((res) => {
            this.$store.commit("newObservation", res.data);
            this.$store.commit("cleanAppSidebar");
            this.$emitter.$emit("Notification", { variant: "success", title: "İşlem Başarılı" });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
};
</script>

<style></style>
