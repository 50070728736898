<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="addEmployeeForm">
      <app-select-input
        v-model="employees"
        name="Çalışanlar"
        select_label="fullname"
        placeholder="Çalışan Seçiniz..."
        :options="options"
        label="Çalışan:"
        :reduce="(option) => option._id"
        rules="required"
        multiple
      >
        <template #list-header>
          <div class="p-50 m-25 border rounded d-flex gap-3">
            <app-button text="Tümünü Seç" @click="selectAll" />
            <app-button text="Seçimleri Kaldır" @click="deSelectAll" />
          </div>
        </template>
      </app-select-input>
      <hr />
      <app-button @click="addEmployees" text="Ekle" icon="PlusIcon" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: { ValidationObserver },
  data() {
    return {
      options: [],
      employees: [],
    };
  },
  computed: {
    training() {
      return this.$store.getters.appModalItem;
    },
  },
  methods: {
    addEmployees() {
      this.$validate(this.$refs.addEmployeeForm, () => {
        this.$axios
          .patch(`/trainings/${this.training?._id}/add-employee`, { employeeIds: this.employees }, { loading: "sidebar" })
          .then((result) => {
            this.$store.commit("setAppModalOptions", { item: result.data });
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success", message: "Çalışanlar eklendi." });
            this.$store.commit("cleanAppModalSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message ?? null });
          });
      });
    },
    getOptions() {
      this.$axios
        .get(`/trainings/${this.training?._id}/add-employee-options`, { params: { branch: this.training?.branch?._id } })
        .then((result) => {
          this.options = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectAll() {
      this.employees = this.options.map((item) => item._id);
    },
    deSelectAll() {
      this.employees = [];
    },
  },
  mounted() {
    this.getOptions();
  },
};
</script>

<style></style>
